import type { Post } from "~/sdk/qs1/index.server";
import type { IndexLoader } from "../../types";

import { useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Container, Flex, GridV2, Section } from "sparta";
import useBreakpoint from "~/utils/hooks/useBreakpoint";
import LargeModule from "./LargeModule";
import SmallModule from "./SmallModule";

interface IFeaturedHero {
  loaderData: IndexLoader;
}

export default function FeaturedHero() {
  const loaderData = useLoaderData<IndexLoader>(); // Use the useLoaderData hook to get the loader data
  const { featuredPost, latestPosts } = loaderData;
  // window size
  const isMobile = useBreakpoint("mobile");

  return (
    <Container overflowY="hidden" overflowX="hidden">
      <Flex align="center" justify="center">
        <GridV2
          columns={{
            initial: "1",
            md: "2",
            lg: "2fr 1fr",
          }}
          rows={{
            initial: "2",
            md: "1",
          }}
          gap="2"
          width="100%"
          height="430px"
        >
          {featuredPost && <LargeModule article={featuredPost} />}
          <Section column={!isMobile} id="featured-col">
            {latestPosts?.map((article) => (
              <SmallModule key={article.title} article={article} />
            ))}
          </Section>
        </GridV2>
      </Flex>
    </Container>
  );
}
