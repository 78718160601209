import { Link, useParams } from "@remix-run/react";
import { Button, Flex, Image, Text } from "sparta";
import fallback from "~/assets/fallback.png";
import type { Post } from "~/sdk/qs1/index.server";
import useBreakpoint from "~/utils/hooks/useBreakpoint";
import { postTypeToCategory } from "~/utils/posts";

interface ISmallModule {
  article: Post;
}

export default function SmallModule({ article }: ISmallModule) {
  const isMobile = useBreakpoint("mobile");
  const params = useParams();
  const platform = params.platform ?? article?.platform?.[0]?.name?.toLowerCase();

  // get date string
  const dateStr = new Date(article.publishedAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const readTimeStr = `${article.readTime < 1 ? "~" : ""}${article.readTime} min${
    article.readTime < 1 ? "s" : ""
  } read`;

  const displayableTitle =
    article?.title?.length > 50 && isMobile ? `${article?.title?.slice(0, 50)}...` : article?.title;

  return (
    <Image
      src={article?.thumbnail?.url?.length ? article?.thumbnail?.url : fallback}
      alt={article?.title}
      width="100%"
      height="`150px`"
      className="small-module"
      center
    >
      <Flex
        direction={{
          initial: "column",
          md: "row",
        }}
        height="100%"
        width="100%"
        gap="0"
      >
        <Flex className="small-module-image" gap="0" width="100%" height="100%">
          <Link to={`/${platform}/posts/${article?.slug}`}>
            <Button>Read More</Button>
          </Link>
        </Flex>
        <Flex className="small-module-title" direction="column" gap="0" width="100%" height="100%">
          <Flex flexGrow="0" direction="column" gap="0">
            <Flex
              align={{
                initial: "start",
                md: "center",
              }}
              style={{ marginBottom: 10 }}
              direction={{
                initial: "column",
                md: "row",
              }}
              gap="0"
            >
              <Text bold>
                {postTypeToCategory(article.postType)} {!isMobile && "•"}
              </Text>
              <Text
                inline
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  marginLeft: 5,
                }}
              >
                {readTimeStr}
              </Text>
            </Flex>
            <Text
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                lineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {displayableTitle}
            </Text>
          </Flex>
          <Flex align="end">
            <Text id="small-module-date">{dateStr}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Image>
  );
}
