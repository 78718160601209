import { Platform } from "~/sdk/qs1/index.server";

/**
 * This method will return the category of a post based on its post type
 * @param postType the post type
 * @returns the category
 */
export function postTypeToCategory(postType: string): string {
  // translate category from post type
  switch (postType) {
    case "news":
      return "News";
    case "events":
      return "Events";
    case "singleAward":
      return "Single Award";
    case "multipleAward":
      return "Multiple Awards";
    case "taskOrder":
      return "Task Order";
    case "delayedAward":
      return "Delayed Award";
    case "protests":
      return "Protests";
    case "industryMoves":
      return "Industry Moves";
    case "jobs":
      return "Jobs";
    default:
      return "Opportunities";
  }
}

/**
 * Get the live path for a content item. The live path is the path that the user will be redirected to when they click on the content item.
 * The live path is determined by the user's access to the platform the content item is published to. If the user has access to the platform the content item is published to, the live path will be the platform the content item is published to. If the user does not have access to the platform the content item is published to, the live path will be the first platform the user has access to that the content item is published to.
 * If the user does not have access to any platform the content item is published to, the live path will be the first platform the content item is published to.
 *
 * @param user - The user for which the live path is retrieved.
 * @param currentPlatform - The name of the platform the user is currently on.
 *
 * @returns The live path for the content item.
 */
export function getLivePath(
  slug: string,
  postPlatforms: Platform[],
  userPlatforms?: Platform[],
  currentPlatform?: Platform,
): string {
  // Check if the post is published to the current platform
  const postIsPublishedToCurrentPlatform = postPlatforms?.includes(currentPlatform);

  // Set the path platform to the current platform if the post is published to the current platform
  let pathPlatform = postIsPublishedToCurrentPlatform ? currentPlatform : postPlatforms[0];

  if (!userPlatforms) return `/${pathPlatform.toLowerCase()}/posts/${slug}`;
  
  // Check if the user has access to the platform in the path
  const userCanAccessPlatform = userPlatforms.includes(pathPlatform);

  if (!userCanAccessPlatform) {
    // If the user does not have access to the platform in the path, find the first platform the user has access to
    // that the post is published to
    const platformWithAccess = userPlatforms.find((platform) => postPlatforms.includes(platform));
    if (platformWithAccess) {
      // Set the path platform to the platform the user has access to
      pathPlatform = platformWithAccess;
    }
  }

  // This path will be the current path if the user has access to it and the post is published to it, otherwise it will be the first platform the user has access to that the post is published to
  // If the user does not have access to any platform the post is published to, the path will be the first platform the post is published to
  return `/${pathPlatform.toLowerCase()}/posts/${slug}`;
}
